<template>
  <div class="my-outbox">
    <div class="my-inbox" ref="box">
      <div
        class="my-list"
        v-for="(item, index) in lists"
        :key="index"
        ref="list"
        @click="showDialog(index)"
      >
        <!--{{item.name}}刚刚购买了产品-->
        <div class="my-uname">
          {{ item.content }}
          <a
            v-if="item.url"
            :href="item.url"
            target="_blank"
            class="lookDetail"
            @click.stop
            >查看详情>></a
          >
        </div>
      </div>
    </div>
    <NoticeModel v-model="show" :slider-arr="lists" />
  </div>
</template>

<script>
import NoticeModel from "./NoticeModel.vue";
export default {
  name: "my-marquee-left",
  components: {
    NoticeModel,
  },
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timeOut: null,
      // 定时器标识
      nowTime: null,
      // 每一个内容的宽度
      disArr: [],
      show: false,
      // initialIndex: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const item = this.$refs.list;
      const len = this.lists.length;
      const arr = [];
      // 因为设置的margin值一样，所以取第一个就行。
      const margin = this.getMargin(item[0]);
      for (var i = 0; i < len; i++) {
        arr.push(item[i].clientWidth + margin); // 把宽度和 margin 加起来就是每一个元素需要移动的距离
      }
      this.disArr = arr;
      const lm = arr.reduce(function (pre, value) {
        return pre + value;
      });
      // console.log(lm);
      // console.log(this.disArr);
      if (lm > 874) {
        this.timeOut = setTimeout(() => {
          this.moveLeft();
        }, 3000);
      }
    });
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    // 页面关闭清除定时器
    clearInterval(this.nowTime);
    // 清除定时器标识
    this.nowTime = null;
    this.timeOut = null;
  },
  methods: {
    showDialog(value) {
      // this.initialIndex = value;
      this.show = !this.show;
    },
    // 获取margin属性
    getMargin(obj) {
      let marg = window.getComputedStyle(obj, null)["margin-right"];
      marg = marg.replace("px", "");
      return Number(marg); // 强制转化成数字
    },
    // 移动的方法
    moveLeft() {
      let that = this;
      let outbox = this.$refs.box;
      // 初始位置
      let startDis = 0;
      this.nowTime = setInterval(function () {
        startDis -= 0.5;
        if (Math.abs(startDis) > Math.abs(that.disArr[0])) {
          // 每次移动完一个元素的距离，就把这个元素的宽度
          that.disArr.push(that.disArr.shift());
          // 每次移动完一个元素的距离，就把列表数据的第一项放到最后一项
          that.lists.push(that.lists.shift());
          startDis = 0;
          // console.log('移动')
        }
        // 每次都让盒子移动指定的距离
        outbox.style = `transform: translateX(${startDis}px)`;
      }, 1000 / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-outbox {
  // width: 100%;
  overflow: hidden;
  .my-inbox {
    white-space: nowrap;
    // width: 100%;
    .my-list {
      // min-width: 100%;
      margin-right: 50px;
      // margin-left: 874px;
      display: inline-block;
      font-size: 14px;
      // text-indent: 30px;
      .my-uname {
        cursor: pointer;
      }
      .lookDetail {
        color: var(--blue);
      }
    }
  }
}
</style>
