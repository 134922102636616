<template>
  <div class="home">
    <!-- banner大图 -->
    <div class="banner">
      <img-div
        class-name="flex flex-hc"
        :img-url="require('@/assets/home/1@2x.png')"
        height="320"
      >
        <img :src="require(`@/assets/home/slogan.png`)" class="slogan" />
        <div
          :class="[
            'noticeSlider flex flex-vc animate__animated',
            showNotice ? 'animate__fadeInDown' : 'animate__fadeOutUp',
          ]"
          v-if="noticeList.length > 0"
        >
          <img class="laba" src="@/assets/home/laba.png" alt="" />
          <div class="slider flex flex-vc">
            <!-- <el-carousel height="32px" direction="vertical" autoplay>
              <el-carousel-item
                v-for="item in noticeList"
                :key="item.notice_id"
              >
                <div class="notice flex flex-vc">{{ item.content }}</div>
              </el-carousel-item>
            </el-carousel> -->
            <Marquee :lists="noticeList"></Marquee>
          </div>
          <img
            class="close"
            @click="showNotice = false"
            src="@/assets/home/cha.png"
            alt=""
          />
        </div>
      </img-div>
    </div>
    <div class="advantage">
      <!-- 优势列表 -->
      <div
        class="advantage-list"
        v-for="(item, index) in advantageList"
        :key="index"
      >
        <div
          :class="[
            'advantage-item flex flex-vc flex-jsb container',
            index % 2 === 0 ? '' : 'flex-zRightLeft',
          ]"
        >
          <!-- <el-image
            :src="item.imgSrc"
            fit="contain"
            class="advantage-item_leftImg"
          ></el-image> -->
          <!-- <div class="advantage-item_leftImg"> -->
          <img
            :src="item.imgSrc"
            alt=""
            class="advantage-item_leftImg"
            v-animate-onscroll="{
              down: 'animate__animated animate__fadeInUp',
            }"
          />
          <!-- </div> -->
          <div
            :class="['advantage-item_right', index % 2 === 0 ? '' : 'ml20']"
            v-animate-onscroll="{
              down: 'animate__animated animate__fadeInUp',
            }"
          >
            <div class="advantage-item_right_title">
              {{ item.title }}
            </div>
            <div class="advantage-item_right_desc">
              {{ item.desc }}
            </div>
            <ul>
              <li
                class="flex flex-vc regulations"
                v-for="(liItem, idx) in item.liList"
                :key="idx"
              >
                <!-- <el-image
                  :src="liItem.iconSrc"
                  fit="contain"
                  class="regulations_img"
                ></el-image> -->
                <img :src="liItem.iconSrc" class="regulations_img" alt="" />
                <span>{{ liItem.text }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="links container"
      v-animate-onscroll="{
        down: 'animate__animated animate__fadeInUp',
      }"
    >
      <div class="title">{{ $t("home.qkMember") }}</div>
      <div class="flex flex-jsb linksList">
        <a
          :href="item.url"
          target="_blank"
          v-for="(item, index) in linksArr"
          :key="index"
        >
          <img :src="item.img" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
import Marquee from "@/components/business/Marquee.vue";
import api from "@/api";
export default {
  components: {
    ImgDiv,
    Marquee,
  },
  computed: {
    advantageList() {
      return this.$t("home.advantageList");
    },
  },
  data() {
    return {
      linksArr: [
        {
          img: require("@/assets/home/qkjt.png"),
          url: "http://www.zero2ipo.com.cn",
        },
        {
          img: require("@/assets/home/qkyj.png"),
          url: "https://www.pedata.cn",
        },
        {
          img: require("@/assets/home/sqtyy.png"),
          url: "http://sandhill.zero2ipo.com.cn/",
        },
        {
          img: require("@/assets/home/qkmjj.png"),
          url: "http://fof.zero2ipo.com.cn/portal/index",
        },
        {
          img: require("@/assets/home/tzj.png"),
          url: "https://www.pedaily.cn/",
        },
        // {
        //   img: require("@/assets/home/sqxt.png"),
        //   url: "http://sandcollege.bbvod.net/",
        // },
        // {
        //   img: require("@/assets/home/xmgc.png"),
        //   url: "http://www.pemarket.com.cn/",
        // },
      ],
      noticeList: [],
      showNotice: true,
    };
  },
  created() {
    // console.log(this.$i18n.locale);
    api.common
      .noticeList({ platform: "pc" })
      .then((res) => {
        if (res.list && res.list.length > 0) this.noticeList = res.list;
        // console.log(this.noticeList);
      })
      .catch((err) => {
        console.error(err);
      });
    this.$nextTick(() => {
      window.scrollTo(0, 1);
    });
  },
};
</script>
<style lang="scss" scoped>
.home {
  @keyframes fadeInUps {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .animate__fadeInUp {
    -webkit-animation-name: fadeInUps;
    animation-name: fadeInUps;
  }
  background-color: var(--whiteColor);
  .banner {
    position: relative;
    .slogan {
      width: 230px;
      height: 216px;
      margin-top: 52px;
    }
    .noticeSlider {
      width: 958px;
      height: 32px;
      background: var(--noticeBg);
      border-radius: 2px;
      position: absolute;
      top: 0;
      // left: 0%;
      // transform: translateX(-100%);
      // transform: translateX(50%);
      padding: 0 12px;
      .laba {
        width: 18px;
        height: 18px;
      }
      .close {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
      .slider {
        width: 874px;
        height: 100%;
        margin: 0 9px 0 16px;
        color: var(--blackColor);
        font-size: 12px;
        // ::v-deep .el-carousel__indicators {
        //   display: none;
        // }
        // .notice {
        //   font-size: 12px;
        //   height: 100%;
        //   color: var(--blackColor);
        // }
      }
    }
  }
  .advantage {
    .advantage-list {
      overflow: hidden;
      &:nth-child(even) {
        background-color: var(--bgGray);
      }
      &:nth-child(3) {
        .advantage-item {
          width: 1070px;
        }
      }
      &:nth-child(4) {
        .advantage-item {
          width: 1030px;
        }
      }
    }
    &-item {
      padding: 80px 0 76px 20px;
      &_leftImg {
        width: 510px;
      }
      .ml20 {
        margin-left: 20px;
      }
      &_right {
        font-size: 28px;
        line-height: 40px;
        color: var(--blackColor);
        &_title {
          font-size: 40px;
          line-height: 56px;
          font-weight: 600;
        }
        &_desc {
          margin-top: 8px;
          font-size: 24px;
          line-height: 33px;
          color: var(--iconGray);
        }
        ul {
          margin-top: 24px;
          font-size: 20px;
          line-height: 28px;
          .regulations {
            padding-top: 26px;
            &_img {
              width: 30px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
  .links {
    padding: 40px 0;
    font-size: 16px;
    color: var(--blackColor);
    line-height: 22px;
    .linksList {
      margin-top: 17px;
      a {
        img {
          width: 200px;
        }
      }
    }
  }
}
</style>
