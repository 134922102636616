var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"banner"},[_c('img-div',{attrs:{"class-name":"flex flex-hc","img-url":require('@/assets/home/1@2x.png'),"height":"320"}},[_c('img',{staticClass:"slogan",attrs:{"src":require(`@/assets/home/slogan.png`)}}),(_vm.noticeList.length > 0)?_c('div',{class:[
          'noticeSlider flex flex-vc animate__animated',
          _vm.showNotice ? 'animate__fadeInDown' : 'animate__fadeOutUp',
        ]},[_c('img',{staticClass:"laba",attrs:{"src":require("@/assets/home/laba.png"),"alt":""}}),_c('div',{staticClass:"slider flex flex-vc"},[_c('Marquee',{attrs:{"lists":_vm.noticeList}})],1),_c('img',{staticClass:"close",attrs:{"src":require("@/assets/home/cha.png"),"alt":""},on:{"click":function($event){_vm.showNotice = false}}})]):_vm._e()])],1),_c('div',{staticClass:"advantage"},_vm._l((_vm.advantageList),function(item,index){return _c('div',{key:index,staticClass:"advantage-list"},[_c('div',{class:[
          'advantage-item flex flex-vc flex-jsb container',
          index % 2 === 0 ? '' : 'flex-zRightLeft',
        ]},[_c('img',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
            down: 'animate__animated animate__fadeInUp',
          }),expression:"{\n            down: 'animate__animated animate__fadeInUp',\n          }"}],staticClass:"advantage-item_leftImg",attrs:{"src":item.imgSrc,"alt":""}}),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
            down: 'animate__animated animate__fadeInUp',
          }),expression:"{\n            down: 'animate__animated animate__fadeInUp',\n          }"}],class:['advantage-item_right', index % 2 === 0 ? '' : 'ml20']},[_c('div',{staticClass:"advantage-item_right_title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"advantage-item_right_desc"},[_vm._v(" "+_vm._s(item.desc)+" ")]),_c('ul',_vm._l((item.liList),function(liItem,idx){return _c('li',{key:idx,staticClass:"flex flex-vc regulations"},[_c('img',{staticClass:"regulations_img",attrs:{"src":liItem.iconSrc,"alt":""}}),_c('span',[_vm._v(_vm._s(liItem.text))])])}),0)])])])}),0),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
      down: 'animate__animated animate__fadeInUp',
    }),expression:"{\n      down: 'animate__animated animate__fadeInUp',\n    }"}],staticClass:"links container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.qkMember")))]),_c('div',{staticClass:"flex flex-jsb linksList"},_vm._l((_vm.linksArr),function(item,index){return _c('a',{key:index,attrs:{"href":item.url,"target":"_blank"}},[_c('img',{attrs:{"src":item.img,"alt":""}})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }