<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="320px"
    :show-close="false"
    append-to-body
    top="40vh"
    :close-on-click-modal="false"
    lock-scroll
  >
    <div class="dialog-body">
      <img
        src="../../assets/home/bg_ Notice_top@2x.png"
        class="notice-slogan"
        alt=""
      />
      <el-carousel
        height="150px"
        trigger="click"
        arrow="never"
        indicator-position="outside"
        :interval="3000"
      >
        <!-- :initial-index="initialIndex" -->
        <el-carousel-item v-for="(item, idx) in sliderArr" :key="idx">
          <div class="content">
            {{ item.content }}
            <a
              v-if="item.url"
              :href="item.url"
              target="_blank"
              class="lookDetail"
              >查看详情>></a
            >
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="footer">
        <el-button type="primary" @click="closeWindow">知道了</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // initialIndex: {
    //   type: Number,
    //   default: 0,
    // },
    sliderArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: this.value,
    };
  },
  computed: {},
  watch: {
    value(n, o) {
      if (n === o) return;
      //   if (n) this.setActiveItem(this.initialIndex);
      this.dialogVisible = n;
    },
    dialogVisible(n, o) {
      if (n === o) return;
      this.$emit("input", n);
    },
  },
  created() {},
  mounted() {},
  methods: {
    closeWindow() {
      this.dialogVisible = false;
    },
    goUrl(item) {
      if (item.url) window.open(item.url);
    },
    // setActiveItem(value) {
    //   this.$nextTick(() => {
    //     this.$refs["slider"].setActiveItem(value);
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  background-color: var(--whiteBg);
  //   border-radius: 0px;
  border-radius: 0 0 20px 20px;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 0px;
  }
  .el-dialog__footer {
    padding: 0;
  }
  .el-carousel__indicators--outside {
    button {
      background-color: var(--iconGray);
      opacity: 0.3;
    }
    .el-carousel__indicator.is-active button {
      background-color: var(--blue);
      opacity: 1;
    }
    .el-carousel__indicator--horizontal {
      padding: 32px 4px 8px;
      .el-carousel__button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        //   width: 100%;
        //   height: 100%;
      }
    }
  }

  .el-button--primary {
    background-image: linear-gradient(90deg, #ff941d 0%, #ff5d01 96%);
    border-radius: 20px;
    width: 100%;
    height: 32px;
    padding: 0;
    font-size: 14px;
    border: none;
    font-weight: 400;
    line-height: 32px;
    &:active {
      opacity: 0.7;
    }
  }
}
.dialog-body {
  position: relative;
  padding: 24px 32px 16px;
  .notice-slogan {
    width: 100%;
    // height: 129px;
    position: absolute;
    top: 2px;
    left: 0;
    transform: translateY(-100%);
  }
  .content {
    padding: 0 0px;
    height: 100%;
    overflow-y: auto;
    line-height: 1.5;
    cursor: pointer;
    text-align: justify; //css英文语句的两端对齐：
    .lookDetail {
      color: var(--blue);
    }
  }
  .footer {
    padding: 0 0px;
  }
}
</style>
